<template>
  <div>
  <div>
    <div class="has-background-secondary">
      <div class="container content py-6 mb-6">
        <h1 class="has-text-white my-0">Privacy Policy</h1>
      </div>
    </div>

    <div class="container content pb-6">
      <div style="max-width: 850px">
        <p>
          We are creating Plumb to provide Independent Search, which we are defining as search that lets our customers (not our "users") stay independent. Core to the agreement we are making with our customers is that we don't collect your data. We set no cookies on any device and will not track you across the internet. We are private and anonymous. 
        </p>
        <p>
          We are building Plumb differently on purpose. We believe that there is value in private, anonymous, search that offers <em>deeper</em> results, and that most digital citizens would rather subscribe to search than pay for it with our data for the rest of our lives. We are building the search engine we think most people want. We have found search to be a much better experience when you know you’re not being tracked.
        </p>

        <p>
          Much of the human conversation and much of humanity's wisdom is now on the internet and we believe that every person has a right to a view of it that is unbiased by extreme commercialism, surveillance capitalism, or any particular viewpoint. 
        </p>

        <p>
          Search that’s unintrusive, because there's no need to be. And that deeper because, well, doesn’t everybody want to see more of what’s out there?
        </p>

        <p>
          Plumb’s aim is to be the card catalog of the internet.
        </p>

        <p>
          We are not <external-link href="https://www.publicaffairsbooks.com/titles/shoshana-zuboff/the-age-of-surveillance-capitalism/9781610395700/">surveillance capitalists</external-link>. 
        </p>

        <p>
          Search and the right to privacy are intimately linked. It wasn't always this way. Search didn't always threaten what Ms Zuboff calls the "right to a future tense." We are building Plumb to respect your "future tense," and our privacy policy reflects that.
        </p>

        <p>
          We are betting the company that we can provide a much better search <em>experience</em>, and that the subscription for that will become an afterthought. You'll get an "honest" result, not skewed due to our own commercial needs, nor limited in scope because of them. 
        </p>

        <p>
          We believe Independent Search is a Digital Human Right, and we are building Plumb to provide it. One of our founders wrote a <router-link to="/DoDI">Declaration of Digital Independence</router-link> (DoDI), and that is how we run Plumb.
        </p>

        <p>With that, here is how we protect your privacy at Plumb.one</p>

        <p>
          <strong>We don’t record your IP address.</strong> At all. Plumb monitors its server logs to manage bandwidth and site performance. No Personally Identifiable Information (PII) like your IP address is accessible or used in these audits. We do collect limited non-personally identifying information that your browser makes available, which is not saved after session ends. We also record URLs that are not in our index. No PII is collected as part of this process.
        </p>

        <p>
          <strong>We don’t serve any tracking or identifying cookies.</strong> Plumb places no cookies or tracking technology of any kind on any device. If we are serving results from a third party, we may be required to allow them to do so, however Plumb will never set any of its own. Plumb Mobile does provide even more Independent Search, where no websites can set anything on any device ever. You can download that for iOS here and Android here.
        </p>

        <p>
          <strong>We don't sell data.</strong> It's not our business model and we don't have it to sell, even if we thought it a good idea.
        </p>

        <p>
          <strong>Third parties.</strong> At times Plumb may need to request data from third parties to fulfill search requests. We are contractually obligated to show their ads and follow their policies regarding tracking technology. Plumb will never set its own trackers on any device.
        </p>

        <p>
          <strong>PII.</strong> We comply with both EU's GDPR and California's CCPA. We don't collect any PII (Personally Identifiable Information) that could be tracked back to you.
        </p>

        <p>
          <strong>Ads.</strong> Plumb will show a limited number of ads, especially if we are relying on a third party to answer your search query. These ads are not shown to you due to any tracking nor are they tied to you in any way. 
        </p>

        <p>
          <strong>Affiliate links.</strong> Part of the way we make money is through affiliate links. They will always be fully identified on Plumb.one and Plumb Mobile. These are links to websites where you can buy things. When you do, we make a commission for referring you to the site. We use aggregate affiliate revenue as part of our ranking; we assume that websites where we earn greater commissions are treating our customers better. That is the extent of how we use that data.
        </p>

        <p>
          <strong>Plumb Mobile.</strong> Offers a higher level of security, by offloading most of the "browser" to our servers and only painting your screen. No website is able to set anything on your devices when you browse using Plumb Mobile. 
          <external-link :href="$settings.ANDROID_APP_LINK">Android</external-link>. <external-link :href="$settings.IOS_APP_LINK">iOS</external-link>.
        </p>

        <p>
          <strong>Our index and how we grow it.</strong> When you search for something Plumb doesn't have in its index, we will queue that URL for indexing. As always, no PII is collected during this process, it's simply one way we know what to index next. That’s why, as you use Plumb, it’ll get better.
        </p>

        <p>
          <strong>Further privacy.</strong> We strongly encourage our customers to also use VPN technology for additional privacy. While we can assure you that we set no trackers, once you leave a Plumb website or app, it is no longer under our control.  We strongly encourage our customer to use a VPN as they provide further protection from <external-link href="https://www.publicaffairsbooks.com/titles/shoshana-zuboff/the-age-of-surveillance-capitalism/9781610395700/">surveillance capitalism</external-link>. Plumb is an affiliate of <external-link :href="$settings.VPN_AFFILIATE_LINK">Ambit VPN</external-link>. After an extensive due diligence process, we trust them. We know they are not owned by state actors and have found that they provide superior service
        </p>

        <p>
          <strong>Changes to this policy.</strong> We will, from time to time, make changes to this policy. However, our commitment to Independent Search will remain. Changes here will always be announced on our site and in our apps. And every corporate decision will adhere to the spirit of the DoDI, to the benefit of our customers, and prudent business practices. Our policies and decisions will reflect our goal of “honest results at a fair price.”
        </p>

        <p><strong>Contact us.</strong> For further questions, please contact us at <external-link href="mailto:security@plumb.one" target="_blank">security@plumb.one</external-link></p>

      </div>
    </div>

  </div>
  </div>
</template>

<script>
import ExternalLink from '../components/ExternalLink.vue'
export default {
  components: { ExternalLink },
  name: "PrivacyPolicy"
}
</script>